import logo from './logo.svg';
import './App.css';
import "./firebase";
import React, { useState } from 'react' 
import { UUID } from './uuid';
import { getDatabase, ref, child,set,get } from "firebase/database";
function App() {
const [CODE,setCODE] = useState(UUID);
const [Value, setValue] = useState("");
const [DATA, setDATA] = useState("")
const [Error, setError] = useState("");



const Download=()=>{
  if (Value!=="") {
    const db = ref(getDatabase());
    get(child(db, `${Value}`)).then(res=>{
      if (res.exists()) {
        console.log(res.val());
        setDATA(res.val().value);
        setError("Successfully Downloaded");
      } else {
        console.log("No data available");
        setError("No data available");
      }
     
    }).catch(err=>{
      console.error(err);
    });
   
  }else{
    setError("Add Some Value");
  }
}

const Upload=()=>{
  if (Value!=="") {
    const db = getDatabase();
    set(ref(db,`${CODE}/`),{
      value : Value,
    }).then(res=>{
      console.log("Success");
      localStorage.setItem("code",CODE);
      setCODE(UUID);
      alert("Data Uploaded Successfully...");
      setError("Successfully Added");
    }).catch(err=>{
      console.error(err);
      setError("Some Error Occured");
    });
   
  }else{
    setError("Add Some Value");

  }
}
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
                >
          Learn React
        </a>
        <h1>FIREBASE</h1>
        <textarea defaultValue={""}  cols="50" rows="15" onChange={res=>{setValue(res.target.value)}} ></textarea>
<h6 style={{margin:"0px",color:"red"}}>{CODE}</h6>
      <div>  <button style={{marginRight:'5vw'}} onClick={Upload}>
          Upload
        </button><button onClick={Download}>Download</button></div>
        <div>
          {Error==="Successfully Added"?<h3 style={{color:'green'}}>{Error}</h3>:Error==="Successfully Downloaded"?<h3 style={{color:'green'}}>{Error}</h3>:<h3 style={{color:'red'}}>{Error}</h3>}
        </div>
<h2 style={{margin:"0px"}}>Uploaded</h2>
        <h3 style={{margin:"0px",color:'green'}}>{localStorage.getItem("code")?localStorage.getItem("code"):"Upload First"}</h3>

        <h1>Downloaded Data</h1>
        <textarea  cols="50" rows="15" value={DATA} readOnly style={{margin:"10px"}}></textarea>
      </header>
    </div>
  );
}

export default App;
